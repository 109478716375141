const FAQSection = () => {
    return (
        <section className="faq-section ptb-120" id="roadmap">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7 col-12">
                        <div className="section-heading mb-5 text-center">
                            <h2>Frequently Asked Questions</h2>
                            <p>Completely whiteboard top-line channels and fully tested value. Competently generate
                                testing procedures before visionary maintainable growth strategies for maintainable.</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-7 col-12">
                        <div className="accordion faq-accordion" id="accordionExample">
                            <div className="accordion-item border border-2 active">
                                <h5 className="accordion-header" id="faq-1">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-1" aria-expanded="true">
                                        What is CoolHeadz?
                                    </button>
                                </h5>
                                <div id="collapse-1" className="accordion-collapse collapse show" aria-labelledby="faq-1"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        CoolHeadz is a collection of 2345 awesome characters with elements from different for those who are bored of ordinary NFT projects.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border border-2">
                                <h5 className="accordion-header" id="faq-2">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-2" aria-expanded="false">
                                        What is the mint date?
                                    </button>
                                </h5>
                                <div id="collapse-2" className="accordion-collapse collapse" aria-labelledby="faq-2"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Pre Sale: 27 Jan 2022 - 20:00 (UTC)
                                        <hr></hr>
                                        Public Sale: 29 Jan 2022 - 20:00 (UTC)
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border border-2">
                                <h5 className="accordion-header" id="faq-3">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-3" aria-expanded="false">
                                        What is the mint price?
                                    </button>
                                </h5>
                                <div id="collapse-3" className="accordion-collapse collapse" aria-labelledby="faq-3"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Pre Sale: 1.5 SOL
                                        <hr></hr>
                                        Public Sale: 2 SOL
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item border border-2">
                                <h5 className="accordion-header" id="faq-4">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-4" aria-expanded="false">
                                        Where will be the mint?
                                    </button>
                                </h5>
                                <div id="collapse-4" className="accordion-collapse collapse" aria-labelledby="faq-4"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        The minting will be on CoolHeadz website (coolheadznft.com). After the sell out, we'll be listed on Solanart.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item border border-2">
                                <h5 className="accordion-header" id="faq-5">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-5" aria-expanded="false">
                                        How to mint?
                                    </button>
                                </h5>
                                <div id="collapse-5" className="accordion-collapse collapse" aria-labelledby="faq-5"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Although Solflare, Sollet and several other wallets are supported, we suggest using <strong>Phantom</strong> wallet. Click the <strong>Connect Wallet</strong> button on the website and choose your wallet. When sale is open, <strong>Mint</strong> button will appear. Then, click the Mint button and approve the transaction. Your unique CoolHeadz NFT will be on it's way! 
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item border border-2">
                                <h5 className="accordion-header" id="faq-6">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-6" aria-expanded="false">
                                        Having other questions?
                                    </button>
                                </h5>
                                <div id="collapse-6" className="accordion-collapse collapse" aria-labelledby="faq-6"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Feel free to ping us on Twitter or join our Discord group.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQSection;