const TeamSection = () => {
    return (
        <section className="team-section ptb-120 bg-light">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12">
                        <div className="section-heading text-center">
                            <h2>The Awesome Team</h2>
                            <p>
                                or we can say The People Having Sleepless Nights 😅
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="team-single-wrap mt-5">
                            <div className="team-img">
                                <img src="assets/img/team/324.png" alt="team" className="img-fluid rounded-custom"/>
                            </div>
                            <div className="team-info mt-4">
                                <h5 className="h6 mb-1">Coolio</h5>
                                <p className="text-muted small">Co-founder & Lead Developer</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="team-single-wrap mt-5">
                            <div className="team-img rounded-custom">
                                <img src="assets/img/team/691.png" alt="team" className="img-fluid"/>
                            </div>
                            <div className="team-info mt-4">
                                <h5 className="h6 mb-1">Cooluf</h5>
                                <p className="text-muted small">3D Design Artist</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="team-single-wrap mt-5">
                            <div className="team-img rounded-custom">
                                <img src="assets/img/team/175.png" alt="team" className="img-fluid"/>
                            </div>
                            <div className="team-info mt-4">
                                <h5 className="h6 mb-1">Marcool</h5>
                                <p className="text-muted small">The Marketing Guy</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="team-single-wrap mt-5">
                            <div className="team-img rounded-custom">
                                <img src="assets/img/team/261.png" alt="team" className="img-fluid"/>
                            </div>
                            <div className="team-info mt-4">
                                <h5 className="h6 mb-1">Alcool</h5>
                                <p className="text-muted small">Consultant</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="team-single-wrap mt-5">
                            <div className="team-img rounded-custom">
                                <img src="assets/img/team/29.png" alt="team" className="img-fluid"/>
                            </div>
                            <div className="team-info mt-4">
                                <h5 className="h6 mb-1">AFT</h5>
                                <p className="text-muted small">Community Manager</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="team-single-wrap mt-5">
                            <div className="team-img rounded-custom">
                                <img src="assets/img/team/102.png" alt="team" className="img-fluid"/>
                            </div>
                            <div className="team-info mt-4">
                                <h5 className="h6 mb-1">markozid</h5>
                                <p className="text-muted small">Community Manager</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="team-single-wrap mt-5">
                            <div className="team-img rounded-custom">
                                <img src="assets/img/team/75.png" alt="team" className="img-fluid"/>
                            </div>
                            <div className="team-info mt-4">
                                <h5 className="h6 mb-1">Yanko</h5>
                                <p className="text-muted small">Community Manager</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TeamSection;