const FeatureSection = () => {
    return (
        <section className="feature-section two-bg-dark-light ptb-120" id="feature">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-6 col-md-6">
                        <div className="image-wrap mb-5 mb-md-0 mb-lg-0 mb-xl-0" data-aos="fade-up">
                            <img src="assets/img/samples/neon.png" alt="feature img"
                                className="img-fluid shadow rounded-custom"/>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6">
                        <div className="feature-content-wrap" data-aos="fade-up" data-aos-delay="50">
                            <h4 className="h5 text-primary">Get Ready</h4>
                            <h2>CoolHeadz Neon Collection Airdrop</h2>
                            <p>CoolHeadz NFT holders will receive an airdrop from our Special Neon Collection in March. This is our way of thanking our believers!</p>
                            <ul className="list-unstyled mt-5">
                                <li className="d-flex align-items-start mb-4">
                                    <div className="icon-box bg-danger rounded me-4">
                                        <i className="fas fa-fingerprint text-white"></i>
                                    </div>
                                    <div className="icon-content">
                                        <h3 className="h5">You've Never Seen Anything Like This</h3>
                                        <p>Special Neon Collection combines the characteristic CoolHeadz avatar with a beautiful neon touch.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FeatureSection;