const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="footer-top bg-dark text-white  ptb-60" style={{ background: "url('assets/img/page-header-bg.svg')no-repeat bottom right" }}>
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-8 col-lg-4 mb-md-4 mb-lg-0">
                            <div className="footer-single-col">
                                <div className="footer-single-col mb-4">
                                    <img src="/logo_white.png" alt="logo" className="img-fluid logo-white" style={{ height: "60px" }} />
                                    <img src="/logo_dark.png" alt="logo" className="img-fluid logo-color" />
                                </div>
                                <p>Our latest news, articles, and resources, we will sent to
                                    your inbox weekly.</p>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0">
                            <div className="row justify-content-lg-start mt-60">
                                <div className="col-12">
                                    <a href="https://discord.gg/tWhSaam8xP" className="btn btn-sm btn-discord text-white me-3"><i
                                        className="fab fa-discord"></i> Discord</a>
                                    <a href="https://instagram.com/coolheadznft" className="btn btn-sm btn-instagram text-white me-3"><i
                                        className="fab fa-instagram"></i> Instagram</a>
                                    <a href="https://twitter.com/coolheadznft" className="btn btn-sm btn-twitter text-white me-3"><i
                                        className="fab fa-twitter"></i> Twitter</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;