const RoadmapSection = () => {
    return (
        <section className="work-process ptb-120" id="roadmap">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-10">
                        <div className="section-heading text-center" data-aos="fade-up">
                            <h2>Roadmap</h2>
                            <p>Enthusiastically engage cross-media leadership skills for alternative experiences.
                                Proactively drive vertical systems than intuitive architectures.</p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-5 col-md-12 order-1 order-lg-0">
                        <div className="img-wrap" data-aos="fade-up" data-aos-delay="50">
                            <img src="assets/img/samples/100.png" alt="work process" className="img-fluid rounded-custom"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 order-0 order-lg-1">
                        <ul className="work-process-list list-unstyled">
                            <li className="d-flex align-items-start mb-4" data-aos="fade-up" data-aos-delay="50">
                                <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                                    <i className="far fa-folder-tree fa-2x"></i>
                                </div>
                                <div className="icon-content">
                                    <span className="text-primary h6">Step 1</span>
                                    <h3 className="h5 mb-2">Hello fam! Get Ready for Surprises!</h3>
                                    <p>Our awesome collection CoolHeadz will drop in January 2022. A new special NEON collection of CoolHeadz will be airdropped to holders! In addition, owners of 2 items will receive a special CoolHeadz t-shirt, owners of 3+ items will receive a fantastic 3D model.
                                    </p>
                                </div>
                            </li>
                            <li className="d-flex align-items-start mb-4" data-aos="fade-up" data-aos-delay="100">
                                <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                                    <i className="far fa-bezier-curve fa-2x"></i>
                                </div>
                                <div className="icon-content">
                                    <span className="text-primary h6">Step 2</span>
                                    <h3 className="h5 mb-2">CoolDAO</h3>
                                    <p>By forming the CoolDAO, holders will have right to make suggestions and vote. This project is ours, we all have responsibilities.
                                    </p>
                                </div>
                            </li>
                            <li className="d-flex align-items-start mb-4" data-aos="fade-up" data-aos-delay="150">
                                <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                                    <i className="far fa-layer-group fa-2x"></i>
                                </div>
                                <div className="icon-content">
                                    <span className="text-primary h6">Step 3</span>
                                    <h3 className="h5 mb-2">CoolHeadz - Female Version</h3>
                                    <p>Female version of this unique collection will be released. It will be fascinating again!
                                    </p>
                                </div>
                            </li>
                            <li className="d-flex align-items-start mb-4 mb-lg-0" data-aos="fade-up" data-aos-delay="200">
                                <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                                    <i className="far fa-truck fa-2x"></i>
                                </div>
                                <div className="icon-content">
                                    <span className="text-primary h6">Step 4</span>
                                    <h3 className="h5 mb-2">Utility Token, Staking & P2E</h3>
                                    <p>We've been planning to launch our utility token and a staking platform followed by a P2E game. We'll shape the future with the DAO members!
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RoadmapSection;