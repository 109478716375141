const InfoSection = () => {
    return (
        <section className="cta-section bg-light ptb-120 position-relative overflow-hidden">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-5 col-md-12">
                        <div className="position-relative z-5">
                            <h2>This is the Community of Cool People</h2>
                            <p>Everyday hundreds of carbon-copy NFT projects are being launched. This collection is for the people who do not feel special with 2D punk, ape, or other animal NFTs. With the significant amount of attention given to details, this collection is to show how eye-catching NFT collections can be. Check the samples below and decide yourself.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="row align-items-center justify-content-center position-relative z-2">
                            <div className="col-md-6">
                                <img src="assets/img/samples/1.jpeg" alt="CoolHeadz Featured Image" className="img-fluid shadow rounded-custom mt-5"/>
                            </div>
                            <div className="col-md-6">
                                <img src="assets/img/samples/100.png" alt="CoolHeadz Featured Image" className="img-fluid shadow rounded-custom mb-5"/>
                            </div>
                            <div className="bg-circle rounded-circle position-absolute z--1">
                                <img src="assets/img/shape/blob.svg" alt="Shape" className="img-fluid rounded" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InfoSection;