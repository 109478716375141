import { useEffect, useMemo, useState, useCallback } from 'react';
import * as anchor from '@project-serum/anchor';

import styled from 'styled-components';
import { Container, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { PublicKey } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletDialogButton } from '@solana/wallet-adapter-material-ui';
import {
  awaitTransactionSignatureConfirmation,
  CandyMachineAccount,
  CANDY_MACHINE_PROGRAM,
  getCandyMachineState,
  mintOneToken,
} from '../candy-machine';
import { AlertState } from '../utils';
import { Header } from '../Header';
import { MintButton } from '../MintButton';
import { GatewayProvider } from '@civic/solana-gateway-react';

const ConnectButton = styled(WalletDialogButton)`
  width: auto;
  margin-top: 10px;
  margin-bottom: 5px;
  color: var(--bs-white);
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  -webkit-box-shadow: 0 11px 18px -8px rgb(23 92 255 / 60%);
  box-shadow: 0 11px 18px -8px rgb(23 92 255 / 60%);
  font-size: 16px;
  font-weight: 500;
  font-family: var(--bs-font-monospace) !important;
  border-radius: 0.375rem !important;
  padding: 0.65rem 1.75rem !important;
`;

const MintContainer = styled.div``; // add your owns styles here

export interface HomeProps {
  candyMachineId?: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  txTimeout: number;
  rpcHost: string;
}

const Hero = (props: HomeProps) => {
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: '',
    severity: undefined,
  });

  const rpcUrl = props.rpcHost;
  const wallet = useWallet();

  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  const refreshCandyMachineState = useCallback(async () => {
    if (!anchorWallet) {
      return;
    }

    if (props.candyMachineId) {
      try {
        const cndy = await getCandyMachineState(
          anchorWallet,
          props.candyMachineId,
          props.connection,
        );
        setCandyMachine(cndy);
      } catch (e) {
        console.log('There was a problem fetching Candy Machine state');
        console.log(e);
      }
    }
  }, [anchorWallet, props.candyMachineId, props.connection]);

  const onMint = async () => {
    try {
      setIsUserMinting(true);
      document.getElementById('#identity')?.click();
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        const mintTxId = (
          await mintOneToken(candyMachine, wallet.publicKey)
        )[0];

        let status: any = { err: true };
        if (mintTxId) {
          status = await awaitTransactionSignatureConfirmation(
            mintTxId,
            props.txTimeout,
            props.connection,
            true,
          );
        }

        if (status && !status.err) {
          setAlertState({
            open: true,
            message: 'Congratulations! Mint succeeded!',
            severity: 'success',
          });

          // @ts-ignore
          fbq('trackCustom', 'MintSuccessful');
        } else {
          setAlertState({
            open: true,
            message: 'Mint failed! Please try again!',
            severity: 'error',
          });
        }
      }
    } catch (error: any) {
      let message = error.msg || 'Minting failed! Please try again!';
      if (!error.msg) {
        if (!error.message) {
          message = 'Transaction Timeout! Please try again.';
        } else if (error.message.indexOf('0x137')) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          window.location.reload();
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: 'error',
      });
    } finally {
      setIsUserMinting(false);
    }
  };

  useEffect(() => {
    refreshCandyMachineState();
  }, [
    anchorWallet,
    props.candyMachineId,
    props.connection,
    refreshCandyMachineState,
  ]);

  return (
    <section className="hero-section ptb-120 bg-light" style={{ background: "url('/assets/img/shape/dot-dot-wave-shape.svg')no-repeat bottom center" }}>
      <div className="container">
        <div className="row align-items-center justify-content-lg-between">
          <div className="col-xl-5 col-lg-5">
            <div className="hero-content-wrap text-center text-xl-start text-lg-start" data-aos="fade-right">
              <h1 className="fw-bold display-5">The Most Iconic NFT Collection on Solana!</h1>
              <p className="lead">2345 unique and algorithmically generated 3D Cool characters. <strong>The first NFT collection with awesome 3D backgrounds!</strong></p>
              <div className='action-btn mt-5 align-items-center d-block'>
                {!wallet.connected ? (
                  <ConnectButton>Connect Wallet</ConnectButton>
                ) : (
                  <>
                    <Header candyMachine={candyMachine} />
                    <MintContainer>
                      {candyMachine?.state.isActive &&
                        candyMachine?.state.gatekeeper &&
                        wallet.publicKey &&
                        wallet.signTransaction ? (
                        <GatewayProvider
                          wallet={{
                            publicKey:
                              wallet.publicKey ||
                              new PublicKey(CANDY_MACHINE_PROGRAM),
                            //@ts-ignore
                            signTransaction: wallet.signTransaction,
                          }}
                          gatekeeperNetwork={
                            candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                          }
                          clusterUrl={rpcUrl}
                          options={{ autoShowModal: false }}
                        >
                          <MintButton
                            candyMachine={candyMachine}
                            isMinting={isUserMinting}
                            onMint={onMint}
                          />
                        </GatewayProvider>
                      ) : (
                        <MintButton
                          candyMachine={candyMachine}
                          isMinting={isUserMinting}
                          onMint={onMint}
                        />
                      )}
                    </MintContainer>
                  </>
                )}
                <Container>
                  <Snackbar
                    open={alertState.open}
                    autoHideDuration={6000}
                    onClose={() => setAlertState({ ...alertState, open: false })}
                  >
                    <Alert
                      onClose={() => setAlertState({ ...alertState, open: false })}
                      severity={alertState.severity}
                    >
                      {alertState.message}
                    </Alert>
                  </Snackbar>
                </Container>
              </div>
              <div className="row justify-content-lg-start mt-60">
                <h6 className="text-dark-70 mb-2">Join The Community</h6>
                <div className="col-12">
                  <a href="https://discord.gg/tWhSaam8xP" className="btn btn-sm btn-discord text-white me-3"><i
                    className="fab fa-discord"></i> Discord</a>
                  <a href="https://instagram.com/coolheadznft" className="btn btn-sm btn-instagram text-white me-3"><i
                    className="fab fa-instagram"></i> Instagram</a>
                  <a href="https://twitter.com/coolheadznft" className="btn btn-sm btn-twitter text-white me-3"><i
                    className="fab fa-twitter"></i> Twitter</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 mt-4 mt-xl-0">
            <div className="hero-img-wrap position-relative" data-aos="fade-left">
              <ul className="position-absolute animate-element parallax-element shape-service hide-medium">
                <li className="layer" data-depth="0.03">
                  <img src="assets/img/color-shape/image-1.svg" alt="shape"
                    className="img-fluid position-absolute color-shape-1" />
                </li>
                <li className="layer" data-depth="0.02">
                  <img src="assets/img/color-shape/feature-2.svg" alt="shape"
                    className="img-fluid position-absolute color-shape-2 z-5" />
                </li>
                <li className="layer" data-depth="0.03">
                  <img src="assets/img/color-shape/feature-3.svg" alt="shape"
                    className="img-fluid position-absolute color-shape-3" />
                </li>
              </ul>
              <div className="hero-img-wrap position-relative">
                <div className="hero-screen-wrap">
                  <div className="phone-screen">
                    <img src="assets/img/samples/6.jpeg" alt="CoolHeadz Hero Sample"
                      className="position-relative img-fluid rounded-custom" />
                  </div>
                  <div className="mac-screen">
                    <img src="assets/img/samples/2.jpeg" alt="CoolHeadz Hero Sample"
                      className="position-relative img-fluid rounded-custom" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
